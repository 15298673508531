<template>
  <div id="account-activation-page">
    <AppHeader />
    <AppContent
      class="account-activation-content"
      :isFluid="false"
      :isFullpage="true">
      <div class="activation-content">
        <img
          class="icon mb-3"
          src="../../assets/images/no.png"
          alt="Failed"
          v-if="status === 'failed'"
        />
        <img
          class="icon mb-3"
          src="../../assets/images/yes.png"
          alt="Success"
          v-else
        />
        <p class="message"> {{ message }} </p>
      </div>
    </AppContent>

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  export default {
    head : {
      title : {
        inner      : 'Account Activation',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        loading : false,
        message : 'Verifying Account...',
        status  : null,
      }
    },
    components : {
      'AppHeader'  : () => import('../../components/layout/AppHeader'),
      'AppContent' : () => import('../../components/layout/AppContent'),
      'AppLoader'  : () => import('../../components/layout/AppLoader'),
    },
    methods : {

      /**
       * Verify Account
       */
      verify() {
        this.loading = true;
        this.$http.post('auth/verify', {
          userId    : this.$route.params.userId,
          emailHash : this.$route.params.email,
        }).then(response => {
          this.status = 'success';
          this.message = 'Your account has been verified.'
          localStorage.accessToken = response.data.accessToken;
          localStorage.refreshToken = response.data.refreshToken;
          localStorage.expiryAccessTokenUnix =
            response.data.expiryAccessTokenUnix;
          localStorage.expiryAccessTokenDate =
            response.data.expiryAccessTokenDate;
          localStorage.expiryRefreshTokenUnix =
            response.data.expiryRefreshTokenUnix;
          localStorage.expiryRefreshTokenDate =
            response.data.expiryRefreshTokenDate;

          // redirect to main page
          setTimeout(() => this.$store.dispatch('auth/autoLogin'), 5000);
        }).catch(err => {
          this.loading = false;
          this.status = 'failed';
          this.message = err.response.status === 400 ?
            'Activation link is no longer available.' :
            'Unable to activate account.';
        });
      },
    },
    mounted() {
      this.verify();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #account-activation-page {
    position: relative;
    min-height: 100vh;
    background-image: $section1-bg;
    background-attachment: scroll;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .activation-content {
    position: absolute;
    margin: -13% 0 0 -25%;
    top: 50%;
    left: 50%;
    height: 30%;
    width: 50%;
    text-align: center;
  }
  .message {
    font-size: 2em;
  }

  .icon {
    width: 100%;
    height: auto;
    max-width: 8rem;
    min-width: 3rem;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    .message {
      background-color: transparent; //red;
    }
    .activation-content {
      margin: 0 0 0 -25%;
      top: 25%;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .activation-content {
      margin: 0 0 0 -25%;
      top: 30%;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    
  }

  @media only screen and (min-width: $special) {
    
  }

  @media only screen and (min-width: $xl2-min) {
    
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    
  }
</style>